import react, { useEffect } from 'react'

import logo from './logo.svg'
import './App.css'

function App() {
  useEffect(() => {
    console.log('redirecting to https://rhenium-42742.nw.r.appspot.com')
    window.location.href = 'https://rhenium-42742.nw.r.appspot.com'
    return null
  }, [])

  // return <div className="App">Hello, world</div>
}

export default App
